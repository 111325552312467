// material-ui
import { Yard } from "@mui/icons-material";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyIcon from "@mui/icons-material/Key";
import StoreIcon from "@mui/icons-material/Store";
import Typography from "@mui/material/Typography";

// project imports
import { useContext } from "react";
import { EntityContext } from "../../../App";
import NavGroup from "./NavGroup";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const menuItems = {
  items: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "group",
      permission: "Dashboard",
      children: [
        {
          id: "sector",
          title: "Sectors",
          type: "item",
          url: "/admin/dashboard/sectors",
          icon: AnalyticsIcon,
        },
        {
          id: "establishment",
          title: "Establishments",
          type: "item",
          url: "/admin/dashboard/establishments",
          icon: AgricultureIcon,
        },
        {
          id: "dashboard-fertilization",
          title: "Fertilization",
          type: "item",
          url: "/admin/dashboard/fertilization",
          icon: Yard,
        },
        {
          id: "dashboard-yield",
          title: "Yield",
          type: "item",
          url: "/admin/dashboard/yield",
          icon: Yard,
        },
        // {
        //   id: "map",
        //   title: "Map",
        //   type: "item",
        //   url: "/admin/dashboard/map",
        //   icon: MapIcon,
        // },
      ],
    },
    {
      id: "manage",
      title: "Manage",
      type: "group",
      permission: "Management",
      children: [
        {
          id: "users",
          title: "Users",
          type: "item",
          url: "/admin/users",
          icon: GroupsIcon,
          permission: "User.View",
        },
        {
          id: "entities",
          title: "Entities",
          type: "item",
          url: "/admin/entities",
          icon: StoreIcon,
          permission: "Entity.View",
        },
        {
          id: "roles",
          title: "Roles",
          type: "item",
          url: "/admin/roles",
          icon: KeyIcon,
          permission: "Entity.View",
        },
        {
          id: "plotCycles",
          title: "Plot Cycles",
          type: "item",
          url: "/admin/plotCycles",
          icon: KeyIcon,
          // permission: "Entity.View",
        },
      ],
    },
  ],
};

const MenuList = () => {
  const { can } = useContext(EntityContext);

  const navItems = menuItems.items
    .filter((item) => {
      if (!item.permission) return true;
      return can(item.permission);
    })
    .map((item) => {
      switch (item.type) {
        case "group":
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

  return <>{navItems}</>;
};

export default MenuList;
