import { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate, Link as RouterLink } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import AuthService from "../../services/AuthService";
import { AuthContext, EntityContext } from "../../App";
import SwitchEntityDialog from "../switchEntity/SwitchEntityDialog";
import Logo from "../../assets/images/logo.jpg";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleDrawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authState, dispatch } = useContext(AuthContext);

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [settingsMenu, setSettingsMenu] = useState(false);

  const [switchEntityDialog, setSwitchEntityDialog] = useState(false);

  const anchorRef = useRef(null);

  const handleLogout = () => {
    AuthService.logout();
    dispatch({ type: "LOGOUT" });
    return navigate("/", { replace: true });
  };

  //   const handleListItemClick = (event, index, route = "") => {
  //     setSelectedIndex(index);
  //     handleClose(event);
  //     if (route && route !== "") {
  //       navigate(route);
  //     }
  //   };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setSettingsMenu(false);
  };

  const handleToggle = () => {
    setSettingsMenu((prevOpen) => !prevOpen);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButton edge="start" onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
        <img src={Logo} alt="logo" style={{ width: "auto", height: 48 }} />
      </Stack>

      {/* <Typography variant="h6" marginLeft={4}>
          <Button
            variant="text"
            onClick={() => {
              setSwitchEntityDialog(true);
            }}
          >
            {activeEntity?.name}
          </Button>
        </Typography> */}

      <Box sx={{ flexGrow: 1 }} />
      <Box marginRight={4}>
        <Link component={RouterLink} to="/admin/profile">
          {authState.authUser?.name}
        </Link>
      </Box>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        label={<SettingsIcon stroke={1.5} size="1.5rem" />}
        ref={anchorRef}
        aria-controls={settingsMenu ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={settingsMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ px: 1.5 }}>
                  <List
                    component="nav"
                    sx={{
                      width: "100%",
                      //   maxWidth: 350,
                      //   minWidth: 300,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: "10px",
                      [theme.breakpoints.down("md")]: {
                        minWidth: "100%",
                      },
                      "& .MuiListItemButton-root": {
                        mt: 0.5,
                      },
                    }}
                  >
                    <ListItemButton selected={selectedIndex === 4} onClick={handleLogout}>
                      <ListItemIcon>
                        <LogoutIcon stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                    </ListItemButton>
                  </List>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      {/* Temporarily disabled */}
      {/* <SwitchEntityDialog visible={switchEntityDialog} setVisible={setSwitchEntityDialog} /> */}
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
